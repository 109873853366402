import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';

export const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('userCredential'));

  if (user) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
};
