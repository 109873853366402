import React, { useState } from 'react';
import { LuArrowBigUpDash } from "react-icons/lu";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const top = document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(top > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`back-to-top ${isVisible ? 'show' : ''}`} onClick={scrollToTop}>
      <span className='text-white b2top'><LuArrowBigUpDash /></span>
    </div>
  );
};

export default BackToTopButton;
