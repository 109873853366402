import React from 'react';
import Button from 'react-bootstrap/Button';  
import "./AboutButton.css";
import {
    Container,
    Row,
    Col
  } from "react-bootstrap";

  import { Link } from 'react-router-dom';

// button icons
import { FaSchool } from "react-icons/fa6";
import { PiChalkboardTeacherFill } from "react-icons/pi";
import { PiStudent } from "react-icons/pi";
import { ImStatsDots } from "react-icons/im";
import { MdOutlineEventNote } from "react-icons/md";
import { FaPhotoFilm } from "react-icons/fa6";

const AboutButtons = () => {
 
    return (
   
  <Container className="py-4">
  <h3 className="text-center pb-5 underline">
      <ins>Know About</ins>
    </h3>
    <Row className="mb-4">
      <Col className="action_Button_Group">
      <Link  className=" btn nav-link " to='/about'><Button  variant="primary" className="action_Buttons" size="lg" ><FaSchool />School</Button></Link>
      <Link  className=" btn nav-link " to='/staff'><Button variant="primary" className="action_Buttons" size="lg" ><PiChalkboardTeacherFill />Teachers</Button></Link>
      <Link  className=" btn nav-link " to='/student'><Button variant="primary" className="action_Buttons" size="lg" ><PiStudent />Students</Button></Link>
      <Link  className=" btn nav-link " to='/result'>  <Button variant="primary" className="action_Buttons" size="lg" ><ImStatsDots />Result</Button></Link>
      <Link  className=" btn nav-link " to='/events'> <Button variant="primary" className="action_Buttons" size="lg" ><MdOutlineEventNote />Events</Button></Link>
      <Link  className=" btn nav-link " to='/gallery'> <Button variant="primary" className="action_Buttons" size="lg" ><FaPhotoFilm />Gallery</Button></Link>
       
        {/* <Link  className=" btn nav-link " to='/about'></Link> */}
        
      </Col>
    </Row>
  </Container>
  );
};

export default AboutButtons;
