import React from "react";
import Marquee from "react-fast-marquee";
import NewsUpdate from "../componets/NewsUpdate";
import AboutButtons from "../componets/AboutButtons";
import ServiceCard from "../componets/ServiceCard";


import {
  Container,
  Row,
  Col
} from "react-bootstrap";

import studentImg1 from "../images/students.jpg";
import studentImg2 from "../images/students_2.jpg";
import { FiPhoneCall } from "react-icons/fi";

// import Button from 'react-bootstrap/Button';
import Image from "react-bootstrap/Image";



const Student = () => {
  return (
    <div className="page-content">
      <Container className="page-header" fluid>
        <h2 className="text-white text-center">Student Section</h2>
      </Container>

      <div>
        <Marquee className="News_tiker" pauseOnHover>
          &nbsp;&nbsp; &#8680; &nbsp;&nbsp; यहाँ विद्यार्थी के लिए आवश्यक सुचना
          दिखाई जाएगी जो की परीक्षा या किसी अन्य प्रयोजन के लिए दी गई हो
          &nbsp;&nbsp;

          <a className=" pk-backround-white " target="_blank" rel="noreferrer" href="https://vidyarthi24.netlify.app/"> Vidyarthi-24 &nbsp; ऑनलाइन ब्लॉग द्वारा पढ़ें</a>
        </Marquee>

        {/* Testimonials/Success Stories Section */}

        {/* Welcome Message Sention */}
        <Container fluid className="pt-5 pb-4 bg-light">
          <Row className="mb-4 gap-4">
            <Col className="d-sm-flex flex-wrap  align-content-center " md={8}>
              <Col md={5} className="p-3 d-flex flex-column ">
                <h5 className="mb-3 text-center">
                  विद्यार्थियों के लिए सन्देश{" "}
                </h5>
                <Image
                  className="profileImg img-thumbnail"
                  src={studentImg1}
                  rounded
                />
                <br/>
                 <Image
                  className="profileImg img-thumbnail"
                  src={studentImg2}
                  rounded
                />
              </Col>
              <Col md={6} className="p-4">
                <p>
                  प्रिय विद्यार्थियों, <br/><br/>
                  <blockquote>
                  आपका स्वागत है! राजकीय उच्च माध्यमिक
                  विद्यालय भोपालपुरा में आपका हार्दिक स्वागत है!
                  </blockquote>
                  <hr/>
                  हम यहाँ आपको उत्कृष्ट शिक्षा, सहायक सेवाएं, और विविध गतिविधियाँ प्रदान करने
                  के लिए प्रतिबद्ध हैं। हमारे साथ, आप अपने सपनों को साकार कर
                  सकते हैं।<hr/> क्या उपलब्ध है? <br/>शैक्षणिक उत्कृष्टता : विविध
                  पाठ्यक्रम और अनुभवी शिक्षक|<br/> अतिरिक्त गतिविधियाँ : खेल, क्लब,
                  और सामुदायिक सेवा। समर्थन सेवाएं : परामर्श, अकादमिक सलाह, और
                  स्वास्थ्य सेवाएं। 
                  <br/><br/>
                  <a className="pk-nav-btn pk-nav-btn1 nav-link btn " href="https://vidyarthi24.netlify.app/"> Vidyarthi-24 &nbsp; ऑनलाइन ब्लॉग द्वारा पढ़ें</a>
                  <hr/>
                  संपर्क करें <br/>आपके कोई प्रश्न हों तो हमसे संपर्क करें। हमारे विद्यालय  में आपका भविष्य उज्ज्वल है! <br/><br/>
                <a className="pk-nav-btn pk-nav-btn1 nav-link btn " href="tel:+910123456789"> <FiPhoneCall /> &nbsp; अभी कॉल करें </a>
                </p>
              </Col>
            </Col>
            <Col md={3} className="mb-3 ">
            <NewsUpdate/>
            </Col>
          </Row>
        </Container>

        {/* Highlights/Services Section */}
        <ServiceCard />
        <AboutButtons />
      </div>
    </div>
  );
};

export default Student;
