import React from 'react';
import { Container, Card, Row, Col } from "react-bootstrap";
import './Staff.css';
import '../style/pagename.css'
import AboutButtons from '../componets/AboutButtons';


const Staff = () => {
  const teachers = [
    { 
      name: 'Ranjeet Kumar',
      subject: 'Hindi',
      description: 'In. Principal | Lecturer 1 Gr.',
      image: 'Image/principle.jpg',
    },
    { 
      name: 'Umashankar ',
      subject: 'Pol. Science',
      description: 'Lecturer 1 Gr.',
      image: 'Image/uma_shankar.png',
    },
    { 
      name: 'Silochana',
      subject: 'English',
      description: 'Lecturer 1 Gr.',
      image: 'Image/silochana.png',
    },
    { 
      name: 'Jitendra Pareek',
      subject: 'Geography',
      description: 'Lecturer 1 Gr.',
      image: 'Image/jitendra.png',
    },
    { 
      name: 'Sushil Kumar',
      subject: 'Science',
      description: 'Senior Teacher',
      image: 'Image/sushil_kumar.png',
    },
    { 
      name: 'Sita Ram',
      subject: 'Mathematics',
      description: 'Senior Teacher',
      image: 'Image/sitaram.png',
    },
    { 
      name: 'Girdhari Lal',
      subject: 'Sanskrit',
      description: 'Senior Teacher',
      image: 'Image/girdhari_lal.png',
    },
    { 
      name: 'Parvati',
      subject: 'Hindi',
      description: 'Teacher Level-2',
      image: 'Image/parvati.png',
    },
    { 
      name: 'Mubarik',
      subject: 'Mathematics / Science',
      description: 'Teacher Level-2',
      image: 'Image/mubarik.png',
    },
    { 
      name: 'Anil Kumar',
      subject: 'Social Studies',
      description: 'Teacher Level-2',
      image: 'Image/anil.jpg',
    },
    { 
      name: 'Premsukh',
      subject: '-',
      description: 'Teacher Level-1',
      image: 'Image/premsukh.png',
    },
    { 
      name: 'Vijay Singh',
      subject: '-',
      description: 'Teacher Level-1',
      image: 'Image/vijay_singh.png',
    },
    { 
      name: 'Parvinder Singh',
      subject: '-',
      description: 'Teacher Level-1',
      image: 'Image/parvinder_singh.png',
    },
    { 
      name: 'Jagdish Singh',
      subject: '-',
      description: 'Physical Education Teacher',
      image: 'Image/jagdish_singh.png',
    },
    { 
      name: 'Shiv Datt ',
      subject: '-',
      description: 'Librarian',
      image: 'Image/shivdatt.png',
    },
    { 
      name: 'Rishi Kumar',
      subject: '-',
      description: 'Assistant Admin. Officer',
      image: 'Image/rishi_kumar.png',
    },
    { 
      name: 'Pankaj Kumar',
      subject: 'Computer Scince',
      description: 'Basic Computer Instructor',
      image: 'Image/pankaj_kumar.jpg',
    },
    { 
      name: 'Indraj Godara',
      subject: '-',
      description: 'Panchayat Shikshak',
      image: 'Image/indraj.png',
    },
    { 
      name: 'Mohan Lal',
      subject: '-',
      description: 'Panchayat Shikshak',
      image: 'Image/mohanlal.png',
    },
  ];

  return (
    <div className='page-content'>
      <Container className='page-header' fluid>
        <h2 className='text-white text-center'>Our Staff</h2>
      </Container>
   
      <Container className='staff-details my-5'>
        <Row className="d-flex justify-content-around flex-wrap">
          {teachers.map((teacher, index) => (
            <Col key={index} md={3} sm={6} xs={12} className='my-3'>
              <Card className="staff-card">
                <Card.Img className="staff-img" variant="top" src={teacher.image} alt={teacher.name} />
                <Card.Body>
                  <Card.Title className='text-center mt-3'>{teacher.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted text-center">{teacher.subject}</Card.Subtitle>
                  <Card.Text className='text-center'>{teacher.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
     
      <AboutButtons />
    </div>
  );
};

export default Staff;
