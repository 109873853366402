import React, { useState, useEffect } from 'react';
import { createData, updateData, deleteData, onCollUpdate } from '../database/database.service';
import { auth } from '../Firebase';
import { Container, Row, Col, Form, Button, Card, ListGroup } from 'react-bootstrap';
import './createPost.css';

const CreatePost = () => {
    const [newsUpdates, setNewsUpdates] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [metadata, setMetadata] = useState('');
    const [url, setUrl] = useState('');
    const [currentId, setCurrentId] = useState(null);

    useEffect(() => {
        const unsubscribe = onCollUpdate('newsUpdates', (docs) => {
            const sortedDocs = docs.sort((a, b) => b.timestamp - a.timestamp); // Sort by timestamp descending
            setNewsUpdates(sortedDocs);
        });
        return () => unsubscribe();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (auth.currentUser) {
            const newUpdate = {
                title,
                content,
                metadata,
                url,
                timestamp: Date.now() // Add timestamp
            };

            if (currentId) {
                await updateData(`newsUpdates/${currentId}`, newUpdate);
                setCurrentId(null);
            } else {
                await createData('newsUpdates', newUpdate);
            }

            setTitle('');
            setContent('');
            setMetadata('');
            setUrl('');
        } else {
            alert('You must be logged in to create or edit a news update');
        }
    };

    const handleEdit = (update) => {
        setTitle(update.title);
        setContent(update.content);
        setMetadata(update.metadata);
        setUrl(update.url);
        setCurrentId(update.id);
    };

    const handleDelete = async (id) => {
        await deleteData(`newsUpdates/${id}`);
    };

    return (
        <Container className="my-5 create-post-container">
            <Row className="mb-4">
                <Col>
                    <h2 className="mb-4">{currentId ? 'Edit' : 'Create'} a News Update</h2>
                    <Form onSubmit={handleSubmit} className="create-post-form">
                        <Form.Group controlId="title" className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                                placeholder="Enter title"
                            />
                        </Form.Group>
                        <Form.Group controlId="content" className="mb-3">
                            <Form.Label>Content</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                required
                                placeholder="Enter content"
                            />
                        </Form.Group>
                        <Form.Group controlId="metadata" className="mb-3">
                            <Form.Label>Metadata</Form.Label>
                            <Form.Control
                                type="text"
                                value={metadata}
                                onChange={(e) => setMetadata(e.target.value)}
                                required
                                placeholder="Enter metadata"
                            />
                        </Form.Group>
                        <Form.Group controlId="url" className="mb-3">
                            <Form.Label>URL</Form.Label>
                            <Form.Control
                                type="url"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                placeholder="Enter URL"
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="submit-button">
                            {currentId ? 'Update' : 'Submit'}
                        </Button>
                    </Form>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h2>News Updates</h2>
                    <ListGroup>
                        {newsUpdates.map((update) => (
                            <ListGroup.Item key={update.id} className="mb-3 news-update-item">
                                <Card className="news-update-card">
                                    <Card.Header className="news-update-header">
                                        {update.metadata}
                                        <small className="float-end text-muted">
                                            {new Date(update.timestamp).toLocaleString()} {/* Display timestamp */}
                                        </small>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>{update.title}</Card.Title>
                                        <Card.Text>
                                            {update.content}
                                            {update.url && (
                                                <div className="mt-2">
                                                    <a href={update.url} target="_blank" rel="noopener noreferrer">
                                                        Read more
                                                    </a>
                                                </div>
                                            )}
                                        </Card.Text>
                                        <Button variant="warning" onClick={() => handleEdit(update)} className="me-2 edit-button">
                                            Edit
                                        </Button>
                                        <Button variant="danger" onClick={() => handleDelete(update.id)} className="delete-button">
                                            Delete
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
};

export default CreatePost;
