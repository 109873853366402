
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBS0e8lkFCVaZ18ESa0a7lkpY5Xwsg7I-A",
  authDomain: "school-web-9d114.firebaseapp.com",
  projectId: "school-web-9d114",
  storageBucket: "school-web-9d114.appspot.com",
  messagingSenderId: "978983175093",
  appId: "1:978983175093:web:7e46870fc29d91d75a0ffc"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export {
  db,
  auth,
  storage
};





// // src/firebase.js
// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { getFirestore } from 'firebase/firestore';
// // import { collection, addDoc } from 'firebase/firestore';


// const firebaseConfig = {
//   apiKey: "AIzaSyBS0e8lkFCVaZ18ESa0a7lkpY5Xwsg7I-A",
//   authDomain: "school-web-9d114.firebaseapp.com",
//   projectId: "school-web-9d114",
//   storageBucket: "school-web-9d114.appspot.com",
//   messagingSenderId: "978983175093",
//   appId: "1:978983175093:web:7e46870fc29d91d75a0ffc"
// };


// const app = initializeApp(firebaseConfig);

// const db = getFirestore(app);
// const auth = getAuth(app);

// export {
//   db,
//   auth,
//  };

