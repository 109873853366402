import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from "./componets/Header";
import Footer from "./componets/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Staff from "./pages/Staff";
import ContactPage from "./pages/ContactPage";
import Events from "./pages/Events";
import Gallery from "./pages/Gallery";
import Updates from "./pages/Updates";
import NotFound from './pages/NotFound';
import Student from './pages/Student';
import BackToTopButton from './componets/BackToTopButton';
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Result from './pages/Result';
import Createpost from './pages/Createpost';
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from './protectedRoute/ProtectedRoute';

function App() {
  return (
    <>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="staff" element={<Staff />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="events" element={<Events />} />
          <Route path="updates" element={<Updates />} />
          <Route path="student" element={<Student />} />
          <Route path="_AddUserNew" element={<SignUp />} />
          <Route path="login" element={<SignIn />} />
          <Route path="result" element={<Result />} />
          <Route 
            path="createpost" 
            element={
              <ProtectedRoute>
                <Createpost />
              </ProtectedRoute>
            } 
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <BackToTopButton />
        <Footer />
      </div>
    </>
  );
}

export default App;
