import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";
import AboutButtons from "../componets/AboutButtons";
import { createData, deleteData } from '../database/database.service';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../Firebase';
import '../style/pagename.css';

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    // Check if admin is logged in
    const userCredential = JSON.parse(localStorage.getItem('userCredential'));
    if (userCredential) {
      setAdminLoggedIn(true);
      fetchContactData();
    }
  }, []);

  const uploadForm = (data) => {
    createData(`contacts`, data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      phone,
      message,
    };
    uploadForm(data);

    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const fetchContactData = () => {
    const unsubscribe = onSnapshot(collection(db, 'contacts'), (snapshot) => {
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setContacts(data);
    });
    return unsubscribe;
  };

  const handleDelete = async (id) => {
    await deleteData(`contacts/${id}`);
  };

  return (
    <div className="page-content">
      <Container className="page-header" fluid>
        <h2 className="text-white text-center">Contact Us</h2>
      </Container>

      <Container className="pt-5">
        <h5 className="text-center">
          <u>नोट : किसी भी प्रकार की शिकायत या सुझाव के लिए नीचे दिए फॉर्म का उपयोग करें !</u>
        </h5>
      </Container>

      <Container>
        <Row className="align-items-center">
          <Col md={6} className="mb-5">
            <div
              style={{
                height: "500px",
                border: "1px solid #ccc",
                marginBottom: "20px",
              }}
            >
              <iframe
                title="Google Map"
                width="100%"
                height="110%"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3488.0300868970207!2d73.52330067452718!3d29.04569126520062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3915fecfa305e673%3A0x793f8e60de46cfc4!2sGovt.%20Senior%20Secondary%20School%2C%20Bhopalpura!5e0!3m2!1sen!2sin!4v1703517066221!5m2!1sen!2sin`}
                allowFullScreen
              ></iframe>
            </div>
          </Col>
          <Col md={6} className="p-5 mb-5">
            <h3>हम से जुड़ें रहें ......</h3>
            <p>Feel free to reach out to us for any inquiries or feedback.</p>
            <Form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
              <Form.Group controlId="formName">
                <Form.Label className="fw-bold">Name</Form.Label>
                <Form.Control
                  type="text"
                  name="from_name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Your Name"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label className="fw-bold">Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="user_email"
                  placeholder="Enter Email"
                />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label className="fw-bold">Phone</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone"
                  maxLength={10}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label className="fw-bold">Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows={4}
                  placeholder="Enter Your Message"
                  required
                />
              </Form.Group>
              <Button
                variant="primary"
                style={{ background: "#0C356A", border: "#0C356A" }}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      {adminLoggedIn && (
        <Container>
          <h2>Contact Form Submissions</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Message</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => (
                <tr key={contact.id}>
                  <td>{contact.name}</td>
                  <td>{contact.email}</td>
                  <td>{contact.phone}</td>
                  <td>{contact.message}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(contact.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      )}

      <AboutButtons />
    </div>
  );
};

export default ContactPage;
