import React, { useState } from 'react';
import { auth } from '../Firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './formStyle.css';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // To store error messages
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem('userCredential', JSON.stringify(userCredential));
      navigate('/createpost');
      setTimeout(() => {
        window.location.reload();
      }, 1500);
      alert("Welcome Back: " + email);
      setError(null); 
    } catch (error) {
      setError(error.message); 
    }

    setEmail("");
    setPassword("");
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="pk-form-style">
        <h2 className="mb-4">Sign In</h2>
        {error && <Alert variant="danger">{error}</Alert>} {/* Display error message */}
        <Form onSubmit={handleSignIn}>
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
              placeholder="Enter email" 
            />
          </Form.Group>
          <Form.Group controlId="formPassword" className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
              placeholder="Enter password" 
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100">
            Sign In
          </Button>
        </Form>
      </div>
    </Container>
  );
}

export default SignIn;
