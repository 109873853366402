import React, { useEffect, useState } from "react";
import { Container, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { onCollUpdate } from "../database/database.service";
import AboutButtons from "../componets/AboutButtons";
// import "./Updates.css"; // Create this CSS file for custom styles

const Updates = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const unSubEventListener = onCollUpdate("newsUpdates", (docs) => {
      // Sort updates by timestamp in descending order
      const sortedDocs = docs.sort((a, b) => b.timestamp - a.timestamp);
      setNews(sortedDocs);
    });
    return () => unSubEventListener();
  }, []);

  return (
    <div className="page-content">
      <Container className="page-header" fluid>
        <h2 className="text-white text-center">Latest News & Updates</h2>
      </Container>

      <Container className="mt-5">
        <Card>
          <ListGroup>
            {news.length === 0 ? (
              <ListGroupItem className="text-center text-muted">No new updates</ListGroupItem>
            ) : (
              news.map((doc, idx) => (
                <ListGroupItem key={idx}>
                  <div className="news-update-content">
                    <mark>
                      <small className="text-site-color">New: </small>
                      <small className="text-muted">{doc.metadata}</small>
                    </mark>
                    <br />
                    <b>{doc.title}</b>
                    <br />
                    <p>{doc.content}</p>
                    {doc.url && (
                      <a href={doc.url} target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm mt-2">
                        सम्बंधित लिंक &#8605;
                      </a>
                    )}
                    {/* Display timestamp if available */}
                    {doc.timestamp && (
                      <div className="text-muted mt-2">
                        <small>{new Date(doc.timestamp).toLocaleString()}</small>
                      </div>
                    )}
                  </div>
                </ListGroupItem>
              ))
            )}
          </ListGroup>
        </Card>
      </Container>

      <AboutButtons />
    </div>
  );
};

export default Updates;
