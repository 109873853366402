import React, { useEffect, useState } from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { onCollUpdate } from "../database/database.service";
//import "./newsUpdate.css"; // Make sure to create this CSS file for custom styles

function NewsUpdate() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const unSubEventListener = onCollUpdate("newsUpdates", (docs) => {
      // Sort the updates by timestamp in descending order
      const sortedDocs = docs.sort((a, b) => b.timestamp - a.timestamp);
      setNews(sortedDocs.slice(0, 3)); // Limit to top 3 updates
    });
    return () => unSubEventListener();
  }, []);

  return (
    <div className="news-update">
      <h5 className="mb-4">News Updates</h5>
      <Card>
        <ListGroup>
          {news.length === 0 ? (
            <ListGroupItem className="text-center text-muted">No new updates</ListGroupItem>
          ) : (
            <>
              {news.map((doc, idx) => (
                <ListGroupItem key={idx}>
                  <div className="news-update-content">
                    <mark>
                      <small className="text-site-color">New: </small>
                      <small className="text-muted">{doc.metadata}</small>
                    </mark>
                    <br />
                    <b>{doc.title}</b>
                    <br />
                    <p>{doc.content}</p>
                    {doc.url && (
                      <a href={doc.url} target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm mt-2">
                        सम्बंधित लिंक &#8605;
                      </a>
                    )}
                    {/* Display timestamp if available */}
                    {doc.timestamp && (
                      <div className="text-muted mt-2">
                        {/* <small>{new Date(doc.timestamp).toLocaleString()}</small> */}
                      </div>
                    )}
                  </div>
                </ListGroupItem>
              ))}
              {news.length >= 3 && (
                <ListGroupItem style={{ color: "#0C356A", fontWeight: "bold" }}>
                  <a href="/updates" className="news-update-see-all">See all updates...</a>
                </ListGroupItem>
              )}
            </>
          )}
        </ListGroup>
      </Card>
    </div>
  );
}

export default NewsUpdate;
