import {auth} from "../Firebase";
import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import "./Header.css";
import Image from "react-bootstrap/Image";
import Logo from "../images/logo-white.png";
import { FiPhoneCall } from "react-icons/fi";
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('userCredential'));

  const handleNavToggle = () => {
    setExpanded(!expanded);
  };

  const logout = async () => {
    try {
      await auth.signOut(); // Assuming auth is imported and correctly initialized
      localStorage.clear(); // Clear all items from local storage
      setExpanded(false); // Close the navigation menu if it's open
      navigate('/'); // Redirect to the home page or login page
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <Navbar className='pk-nav-top Navbar fixed-top' variant="dark" expand="lg" expanded={expanded}>
      <Container>
        <Link to='/'>
          <Image className="Logo" src={Logo} rounded />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleNavToggle} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Link className="nav-link" to='/' onClick={() => setExpanded(false)}>Home</Link>
            <Link className="nav-link" to='/about' onClick={() => setExpanded(false)}>About</Link>
            <Link className="nav-link" to='/staff' onClick={() => setExpanded(false)}>Staff</Link>
            <Link className="nav-link" to='/student' onClick={() => setExpanded(false)}>Student Section</Link>
            <Link className="nav-link" to='/updates' onClick={() => setExpanded(false)}>Updates</Link>
            <Link className="nav-link" to='/result' onClick={() => setExpanded(false)}>Result</Link>
            <Link className="nav-link" to='/gallery' onClick={() => setExpanded(false)}>Gallery</Link>
            <Link className="nav-link" to='/contact' onClick={() => setExpanded(false)}>Contact</Link>
            {user && <Link className="nav-link" to='/createpost' onClick={() => setExpanded(false)}>Dashboard</Link>}
            <a className="pk-nav-btn nav-link btn font-weight-bold" href="tel:+91 99297 43358">
              <FiPhoneCall /> Call Now
            </a>
            {user ? (
              <Link className="pk-nav-btn nav-link btn font-weight-bold" onClick={logout}>
                Logout
              </Link>
            ) : (
              <Link className="pk-nav-btn nav-link btn font-weight-bold" to='/login' onClick={() => setExpanded(false)}>Login</Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
