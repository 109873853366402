import React, { useEffect } from "react";

const YouTubeSubscribe = () => {
  useEffect(() => {
    // Load the Google Platform script
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="g-ytsubscribe"
      data-channelid="UCAiAQBFk1y4PKVOsSdestRA" // Replace with your channel ID
      data-layout="full"
      data-count="hidden"
    ></div>
  );
};

export default YouTubeSubscribe;
