import React from 'react';
import { Container, Card } from 'react-bootstrap';

const Events = () => {
  // Assuming events is an array of event objects with title, date, and description
  const events = [
    {
      title: 'School Science Fair',
      date: 'December 15, 2023',
      description: 'Join us for an exciting day of science experiments and projects!'
    },
    // Add more event objects as needed
  ];

  return (
<div className="page-contant">
    <Container className="pageName" fluid>
    <h2 className="text-white text-center">Events</h2>
  </Container>
    <Container>
      <h2>Upcoming Events</h2>
      {events.map((event, index) => (
        <Card key={index} className="my-3">
          <Card.Body>
            <Card.Title>{event.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{event.date}</Card.Subtitle>
            <Card.Text>{event.description}</Card.Text>
          </Card.Body>
        </Card>
      ))}
    </Container>
    </div>
  );
};

export default Events;
