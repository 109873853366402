import React, { useState } from 'react';
import { auth } from '../Firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import './formStyle.css';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // To store error messages

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      alert("Please Fill Email And Password");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setError(null); 
      alert("Sign up successful");
    } catch (error) {
      setError(error.message); 
    }

    setEmail("");
    setPassword("");
  };

  return (
    <Container className="pk-form d-flex justify-content-center align-items-center min-vh-100">
      <div className="pk-form-style">
        <h2 className="mb-4">Sign Up</h2>
        {error && <Alert variant="danger">{error}</Alert>} {/* Display error message */}
        <Form onSubmit={handleSignUp}>
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
              placeholder="Enter email" 
            />
          </Form.Group>
          <Form.Group controlId="formPassword" className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
              placeholder="Enter password" 
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100">
            Sign Up
          </Button>
        </Form>
      </div>
    </Container>
  );
}

export default SignUp;
