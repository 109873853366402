import React from "react";
// import Marquee from "react-fast-marquee";
import "./HomePage.css";
import AboutButtons from "../componets/AboutButtons";
import ServiceCard from "../componets/ServiceCard";
import NewsUpdate from "../componets/NewsUpdate";
import HeroSection from "../componets/HeroSection";
import YouTubeSubscribe from "../componets/YouTubeSubscribe"; 
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import logo_a from "../images/logo_a.png";

const HomePage = () => {
  return (
    <div>
      <HeroSection />

      {/* Welcome Message Section */}
      <Container fluid className="pt-5 pb-4 bg-light">
        <Row className="mb-4 gap-4">
          <Col className="d-sm-flex flex-wrap align-content-center" md={8}>
            <Col md={3} className="p-3 d-flex flex-column">
              <h5 className="mb-3 text-center">हमारा विद्यालय.... </h5>
              <Image className="profileImg img-thumbnail" src={logo_a} rounded />
              &nbsp; <YouTubeSubscribe  /> 
            </Col>
            <Col md={8} className="p-4">
              <p>
                प्रिय माता-पिता ,
                <blockquote>
                  "मन एक बर्तन नहीं है जिसे भरना है, बल्कि एक आग है जिसे जलाना है।" – प्लूटार्क
                </blockquote>
                प्रत्येक बच्चे में, एक ऐसी दुनिया होती है जिसका अन्वेषण किया जाना बाकी है। 
                हम एक शांतिप्रिय और सामंजस्यपूर्ण विश्व सुनिश्चित करने के लिए प्रत्येक बच्चे में 
                खुशी की स्थिति पैदा करने के अपने मिशन में प्रतिबद्ध हैं। 
                हमारे स्कूल ने सीखने को सीमाओं से परे ले जाने का प्रयास किया है। 
                हम बच्चों को मजबूत, परिपक्व, दयालु और बुद्धिमान वयस्क बनाने के लिए मानक 
                स्थापित करने के लिए समर्पित हैं, जो अपने विचारों में मौलिक और 
                अपने कार्यों में निर्णायक हैं। इस बात को ध्यान में रखते हुए कि 
                पढ़ाना एक जीवन को हमेशा के लिए छूना है, हम समग्र शिक्षा में विश्वास 
                करते हैं जिसमें शैक्षणिक, सह-पाठ्यचर्या संबंधी गतिविधियाँ, खेल 
                शिक्षा और जीवन-कौशल सीखना शामिल है। परिसर, खेल और शैक्षणिक सुविधाएं 
                सभी इस विश्वास की गवाही देते हैं।
              </p>

              
            </Col>
          </Col>

          <Col md={3} className="mb-3">
         
            <NewsUpdate />
          </Col>
        </Row>
      </Container>

      {/* Highlights/Services Section */}
      <ServiceCard />
      <AboutButtons />
    </div>
  );
};

export default HomePage;
