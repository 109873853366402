import React from "react";
import AboutButtons from "../componets/AboutButtons";
// import { Link } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import '../style/pagename.css';
// import profileImg from "Image/ranjeet_kumar.png";
import { Container, Row, Col, } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import NewsUpdate from "../componets/NewsUpdate"

const AboutPage = () => {
  return (
    <div className="page-content">
      <Container className="page-header" fluid>
        <h2 className="text-white text-center">About Us</h2>
      </Container>

      <Container fluid className="pt-5 pb-4 bg-light">
        <Row className="mb-4 gap-4">
          <Col className="d-sm-flex flex-wrap  align-content-center " md={8}>
            <Col md={3} className="p-3 d-flex flex-column ">
              <h5 className="mb-3 text-center">From Principal Desk</h5>
              <Image
                className="profileImg img-thumbnail"
                src="Image/principle.jpg"
                rounded
              />
              <p className="mt-2 text-center">Sri Ranjeet Kumar</p>
            </Col>
            <Col md={8} className="p-4">
              <p>
                प्रिय माता-पिता ,
                <blockquote>
                  {" "}
                  "मन एक बर्तन नहीं है जिसे भरना है, बल्कि एक आग है जिसे जलाना
                  है।" – प्लूटार्क{" "}
                </blockquote>
                प्रत्येक बच्चे में, एक ऐसी दुनिया होती है जिसका अन्वेषण किया
                जाना बाकी है। हम एक शांतिप्रिय और सामंजस्यपूर्ण विश्व सुनिश्चित
                करने के लिए प्रत्येक बच्चे में खुशी की स्थिति पैदा करने के अपने
                मिशन में प्रतिबद्ध हैं। हमारे स्कूल ने सीखने को सीमाओं से परे ले
                जाने का प्रयास किया है। हम बच्चों को मजबूत, परिपक्व, दयालु और
                बुद्धिमान वयस्क बनाने के लिए मानक स्थापित करने के लिए समर्पित
                हैं, जो अपने विचारों में मौलिक और अपने कार्यों में निर्णायक
                हैं।इस बात को ध्यान में रखते हुए कि पढ़ाना एक जीवन को हमेशा के
                लिए छूना है, हम समग्र शिक्षा में विश्वास करते हैं जिसमें
                शैक्षणिक, सह-पाठ्यचर्या संबंधी गतिविधियाँ, खेल शिक्षा और
                जीवन-कौशल सीखना शामिल है। परिसर, खेल और शैक्षणिक सुविधाएं सभी इस
                विश्वास की गवाही देते हैं।
              </p>
            </Col>
          </Col>
          <Col md={3} className="mb-3 ">
          <NewsUpdate/>
          </Col>
        </Row>
      </Container>

      <section id="history" className="my-5">
        <Container>
          <Row>
            <Col>
              <h2 className="text-center underline">विद्यालय के बारे में </h2>
              {/* History content goes here */}
              <p>
                हमारे विद्यालय का इतिहास गर्व और सम्मान के साथ भरा हुआ है। हमें
                गर्व है कि हमारे छात्र हमेशा उत्कृष्ट प्रदर्शन करते हैं और
                विभिन्न क्षेत्रों में प्रतिस्पर्धा में अपनी पहचान बनाते हैं।
                हमारे विद्यालय के छात्र न केवल शैक्षिक क्षेत्र में अपने
                प्रतिभागिता के लिए प्रसिद्ध हैं, बल्कि खेल, कला, और साहित्य में
                भी उत्कृष्टता का परिचय देते हैं। <br />
                <br /> हमारे विद्यालय में छात्रों को अन्य गतिविधियों के साथ-साथ
                विभिन्न सहायता कार्यक्रम भी प्रदान किए जाते हैं। यह सहायता
                कार्यक्रम छात्रों को उनकी शैक्षिक, सामाजिक, और व्यक्तिगत जरूरतों
                के लिए समर्थन प्रदान करते हैं। हमारा उद्देश्य है सभी छात्रों को
                उत्कृष्टता की ओर अग्रसर करने के लिए आवश्यक संरचना और समर्थन
                प्रदान करना।
              </p>
            </Col>

            
            <a
              className="btn"
              rel="noreferrer"
              target="_blank"
              href="https://rajshaladarpan.nic.in/SD2/Home/Public2/CitizenCorner/schoolprofile.aspx"
            >
              <Button
                variant="primary"
                className="site-bg-color btn-block"
                size="lg"
              >
                अधिक जानकारी के लिए शालादार्पण प्रोफाइल देखें &#8690;
              </Button>
            </a>
          </Row>
        </Container>
      </section>

      {/* Mission & Vision Section */}
      <section id="mission" className="my-5">
        <Container>
          <Row>
            <Col>
              <h2 className="text-center underline">मिशन और दृष्टि</h2>
              {/* Mission & Vision content goes here */}
              <p>
                हमारा मिशन है समाज के विकास में शिक्षा का महत्वपूर्ण योगदान
                देना। हम एक ऐसा शैक्षिक प्रणाली बनाने का प्रयास कर रहे हैं जो
                छात्रों को नैतिक, सामाजिक, और वैज्ञानिक दृष्टिकोण से समृद्ध
                बनाए। हम समर्थ, सक्षम, और संवेदनशील नागरिकों की तैयारी करने का
                प्रयास कर रहे हैं जो समाज के हर क्षेत्र में योगदान कर सकें। हम
                उन्हें वैश्विक समस्याओं का सामना करने के लिए तैयार करना चाहते
                हैं और उन्हें स्वतंत्र, स्वावलंबी, और सच्चे नेता बनाने का मार्ग
                प्रदान करना चाहते हैं।
                <br />
                <br /> हमारी दृष्टि है एक ऐसे समाज की निर्माण करना जो ज्ञान,
                समृद्धि, और समानता से युक्त हो। हम शिक्षा के माध्यम से समाज में
                इंसानीता के मूल्यों को प्रोत्साहित करना चाहते हैं और छात्रों को
                एक सकारात्मक और सहानुभूतिपूर्ण संगठन में योगदान करने के लिए
                प्रेरित करना चाहते हैं। हम विश्वसनीय, उत्कृष्ट, और सामाजिक रूप
                से उत्तम नागरिक बनाने के लिए प्रतिबद्ध हैं जो समाज को एक और
                सुधारते हुए, स्वार्थहीन, और प्रगतिशील समाज में बदलने में मदद कर
                सकें।
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <AboutButtons />
    </div>
  );
};

export default AboutPage;
