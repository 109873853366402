import { addDoc, deleteDoc, doc, updateDoc, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../Firebase';

export const createData = async (collectionName, data) => {
    try {
        const collectionRef = collection(db, collectionName);
        await addDoc(collectionRef, data); // No need to modify data
        alert(`Your Query Submitted Successfully`);
    } catch (e) {
        console.error('Error creating new doc', e);
    }
};

export const updateData = async (path, data) => {
    try {
        const docRef = doc(db, path);
        await updateDoc(docRef, data); // No need to modify data
        alert('Your News Update Updated Successfully');
    } catch (e) {
        console.error('Error updating doc', e);
    }
};

export const deleteData = async (path) => {
    try {
        const docRef = doc(db, path);
        await deleteDoc(docRef);
        alert('Your News Update Deleted Successfully');
    } catch (e) {
        console.error('Error deleting doc', e);
    }
};

export const onCollUpdate = (collectionName, callback) => {
    return onSnapshot(collection(db, collectionName), (snapshot) => {
        const updatedData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        if (callback) callback(updatedData);
    });
};
