import React from "react";
import Marquee from "react-fast-marquee";
import '../style/herosection.css';


import {
  Carousel, 
} from "react-bootstrap";

const HeroSection = () => {
  
  return (
    <div>
 
      <Carousel data-bs-theme="dark" height={350}>

      <Carousel.Item>
          <img
            className="d-block w-100"
            src={'Image/heroimg/pravesh.png'}
            alt="Third slide"
            height={320}
          />
          <Carousel.Caption>
            {/* <h5>प्रेरणा, नवाचार और ज्ञान का संगम</h5> */}
          </Carousel.Caption>
        </Carousel.Item>
      <Carousel.Item>
          <img
            className="d-block w-100"
            src={'Image/heroimg/10result.png'}
            alt="Third slide"
            height={320}
          />
          <Carousel.Caption>
            {/* <h5>प्रेरणा, नवाचार और ज्ञान का संगम</h5> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'Image/heroimg/12result.png'}
            alt="Third slide"
            height={320}
          />
          <Carousel.Caption>
            {/* <h5>प्रेरणा, नवाचार और ज्ञान का संगम</h5> */}
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'Image/heroimg/1.jpg'}
            height={300}
            alt="First slide"
          />
          <Carousel.Caption>
            <h5>हर बच्चे का हक, उच्च गुणवत्ता की शिक्षा</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'Image/heroimg/2.jpg'}
            alt="Second slide"
            height={300}
          />
          <Carousel.Caption>
            <h5>आज की शिक्षा, कल का उज्जवल भविष्य</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'Image/heroimg/3.jpg'}
            alt="Third slide"
            height={300}
          />
          <Carousel.Caption>
            <h5>प्रेरणा, नवाचार और ज्ञान का संगम</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={'Image/heroimg/4.jpg'}
            alt="Third slide"
            height={300}
          />
          <Carousel.Caption>
            <h5>प्रेरणा, नवाचार और ज्ञान का संगम</h5>
          </Carousel.Caption>
        </Carousel.Item>
      
        
      </Carousel>

      <Marquee className="News_tiker" pauseOnHover>
        &nbsp;&nbsp; &#8680; &nbsp;&nbsp; नमस्कार आपका हार्दिक स्वागत है राजकीय
        उच्च माध्यमिक विद्यालय , भोपालपुरा की आधिकारिक वेबसाइट पर , इस वेब-साईट का
        मुख्य उद्देश्य केवल सुचना देने और शिकायत-सुझाव हेतु तत्परता रखना है 
        &nbsp;&nbsp;
        || &nbsp; <a className=" pk-backround-white " rel="noreferrer" target="_blank" href="https://vidyarthi24.netlify.app/"> Vidyarthi-24 &nbsp; ऑनलाइन ब्लॉग द्वारा पढ़ें</a>
        &nbsp;||     
      </Marquee>
    </div>
  ) }

export default HeroSection;
