import React, { useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import "./ServiceCard.css";

import card1 from "../images/lab.png";
import card2 from "../images/smartclass.png";
import card3 from "../images/book.png";
import card4 from "../images/playground.png";
import card5 from "../images/schaler.png";
import card6 from "../images/food.png";
import card7 from "../images/support.png";
import card8 from "../images/geography.png";

const ServiceCard = () => {
  const [showToastScience, setShowToastScience] = useState(false);
  const [showToastArts, setShowToastArts] = useState(false);
  const [showToastDigitalEducation, setShowToastDigitalEducation] = useState(false);
  const [showToastLibrary, setShowToastLibrary] = useState(false);
  const [showToastScholership, setShowToastScholership] = useState(false);
  const [showToastMidDayMeal, setShowToastMidDayMeal] = useState(false);
  const [showToastCareer, setShowToastCareer] = useState(false);
  const [showToastSupports, setShowToastSupports] = useState(false);

  const toggleToastScience = () => setShowToastScience(!showToastScience);
  const toggleToastArts = () => setShowToastArts(!showToastArts);
  const toggleToastDigitalEducation = () => setShowToastDigitalEducation(!showToastDigitalEducation);
  const toggleToastLibrary = () => setShowToastLibrary(!showToastLibrary);
  const toggleToastScholership = () => setShowToastScholership(!showToastScholership);
  const toggleToastMidDayMeal = () => setShowToastMidDayMeal(!showToastMidDayMeal);
  const toggleToastCareer = () => setShowToastCareer(!showToastCareer);
  const toggleToastSupports = () => setShowToastSupports(!showToastSupports);

  return (
    <Container className="py-5">
      <h3 className="text-center pb-5 underline">
        <ins>विद्यालय एक नज़र में</ins>
      </h3>
      <p className="text-secondary">* अधिक जानकारी के लिए बॉक्स पर क्लिक करें </p>
    {/* first row content */}
      <Row>
      { /* Science */ }
        <Col md={3} className="mb-3">
          <Card className="serv-card" onClick={toggleToastScience}>
            <Card.Img variant="top" className="card-img" src={card1} />
            <Card.Body>
              <Card.Title>विज्ञान संकाय</Card.Title>
              <Card.Text>
                रसायन विज्ञान,भौतिक विज्ञान,जीव विज्ञान विषयों के साथ
              </Card.Text>
            </Card.Body>
          </Card>
          {showToastScience && <div className="overlay" onClick={toggleToastScience}></div>}
          <Toast className="toast" show={showToastScience} onClose={toggleToastScience}>
            <Toast.Header>
              <strong className="mr-auto">विज्ञान संकाय</strong>
            </Toast.Header>
            <Toast.Body>
            विज्ञान संकाय अनुभाग में आपका स्वागत है! हम आपको उच्च गुणवत्ता
              वाली विज्ञान शिक्षा प्रदान करने के लिए समर्पित हैं, जो जिज्ञासा और
              नवाचार को बढ़ावा देती है।
              <h6 className="text-white pt-3">पाठ्यक्रम की जानकारी</h6>
              विज्ञान धारा में भौतिकी, रसायन और जीवविज्ञान के विषय शामिल हैं।
              हमारे पाठ्यक्रम राष्ट्रीय शैक्षिक मानकों के अनुसार हैं और नवीनतम
              वैज्ञानिक प्रगति को शामिल करते हैं।
              <br />
              <br />
              <b>भौतिक विज्ञान:</b> पदार्थ और ऊर्जा के सिद्धांतों को समझें, गति
              के नियम सीखें और ब्रह्मांड की जटिलताओं को जानें।
              <br />
              <br />
              <b>रसायन विज्ञान:</b> पदार्थों की संरचना, गुण और प्रतिक्रियाओं के
              बारे में जानें और प्रयोगशाला में व्यावहारिक अनुभव प्राप्त करें।
              <br />
              <br />
              <b>जीवविज्ञान: </b> जीवों का अध्ययन करें, कोशिकाओं से लेकर पूरे
              पारिस्थितिकी तंत्र तक, और आनुवंशिकी और जैव विविधता को समझें।
              <br />
              <br />
              -हम विज्ञान क्लब, अनुसंधान परियोजनाएं, शैक्षिक यात्राएं और विज्ञान
              प्रतियोगिताओं में भागीदारी की पेशकश करते हैं, जो छात्रों को सीखने
              और टीम वर्क के कौशल विकसित करने में मदद करते हैं।
            </Toast.Body>
          </Toast>
        </Col>


        {/* Arts */}
        <Col md={3} className="mb-3">
          <Card className="serv-card" onClick={toggleToastArts}>
            <Card.Img variant="top" className="card-img" src={card8} />
            <Card.Body>
              <Card.Title>कला संकाय</Card.Title>
              <Card.Text>
              भूगोल,इतिहास,राजनीति विज्ञान विषयों के साथ
              </Card.Text>
            </Card.Body>
          </Card>
          {showToastArts && <div className="overlay" onClick={toggleToastArts}></div>}
          <Toast className="toast" show={showToastArts} onClose={toggleToastArts}>
            <Toast.Header>
              <strong className="mr-auto">कला संकाय</strong>
            </Toast.Header>
            <Toast.Body>
            कला संकाय अनुभाग में आपका स्वागत है! हम आपको उच्च गुणवत्ता वाली
              कला शिक्षा प्रदान करने के लिए समर्पित हैं, जो रचनात्मकता और
              आलोचनात्मक सोच को बढ़ावा देती है।
              <h6 className="text-white pt-3">पाठ्यक्रम की जानकारी</h6>
              कला धारा में राजनीति विज्ञान, भूगोल और इतिहास के विषय शामिल हैं।
              हमारे पाठ्यक्रम राष्ट्रीय शैक्षिक मानकों के अनुसार हैं और नवीनतम
              शैक्षिक प्रगति को शामिल करते हैं।
              <br />
              <br />
              <b>राजनीति विज्ञान:</b> सरकारों, नीतियों और राजनीतिक प्रक्रियाओं
              को समझें और राजनीतिक सिद्धांतों का अध्ययन करें।
              <br />
              <br />
              <b>भूगोल:</b> : पृथ्वी की संरचना, जलवायु, संसाधनों और मानव
              गतिविधियों का अध्ययन करें।
              <br />
              <br />
              <b>इतिहास: </b> प्राचीन से आधुनिक समय तक के ऐतिहासिक घटनाओं,
              संस्कृतियों और समाजों का अध्ययन करें।
              <br />
              <br />- हम छात्रों को करियर विकल्पों के बारे में जानकारी देने और
              उनके भविष्य की योजना बनाने में मदद करते हैं। कार्यशालाएं और अतिथि
              व्याख्यान छात्रों को प्रेरित करते हैं। हमारी कला धारा का अन्वेषण
              करें और नए अवसरों की खोज करें। कला शिक्षा में हमारी उत्कृष्टता की
              प्रतिबद्धता छात्रों को भविष्य की चुनौतियों का सामना करने के लिए
              तैयार करती है।
            </Toast.Body>
          </Toast>
        </Col>


 {/* Digital Education */}
 <Col md={3} className="mb-3">
          <Card className="serv-card" onClick={toggleToastDigitalEducation}>
            <Card.Img variant="top" className="card-img" src={card2} />
            <Card.Body>
              <Card.Title>डिजिटल शिक्षा</Card.Title>
              <Card.Text>
                इंटरएक्टिव पैनल व आधुनिक कंप्यूटर टेक्नोलॉजी के साथ
              </Card.Text>
            </Card.Body>
          </Card>
          {showToastDigitalEducation && <div className="overlay" onClick={toggleToastDigitalEducation}></div>}
          <Toast className="toast" show={showToastDigitalEducation} onClose={toggleToastDigitalEducation}>
            <Toast.Header>
              <strong className="mr-auto">डिजिटल शिक्षा</strong>
            </Toast.Header>
            <Toast.Body>
            डिजिटल शिक्षा अनुभाग में आपका स्वागत है! हम आपको उच्च गुणवत्ता
              वाली डिजिटल शिक्षा प्रदान करने के लिए समर्पित हैं, जो तकनीकी कौशल
              और नवीनता को बढ़ावा देती है।
              <h6 className="text-white pt-3">पाठ्यक्रम की जानकारी</h6>
              डिजिटल शिक्षा में कंप्यूटर विज्ञान, प्रोग्रामिंग, और डिजिटल मीडिया
              के विषय शामिल हैं। हमारे पाठ्यक्रम राष्ट्रीय शैक्षिक मानकों के
              अनुसार हैं और नवीनतम तकनीकी प्रगति को शामिल करते हैं।
              <br />
              <br />
              <h6 className="text-white pt-3">मुख्य विशेषताएं:</h6>
              <b>कंप्यूटर विज्ञान:</b> कंप्यूटर के बुनियादी सिद्धांतों,
              हार्डवेयर और सॉफ्टवेयर के कार्यों का अध्ययन करें।
              <br />
              <br />
              <b>प्रोग्रामिंग:</b> : विभिन्न प्रोग्रामिंग भाषाओं और तकनीकों का
              उपयोग करके सॉफ्टवेयर और एप्लिकेशन बनाना सीखें।
              <br />
              <br />
              <b>डिजिटल मीडिया: </b> डिजिटल कंटेंट निर्माण, ग्राफिक डिजाइन और
              मल्टीमीडिया प्रोडक्शन के सिद्धांतों को समझें।
              <br />
              <br />- हमारी कक्षाएं और कंप्यूटर लैब्स आधुनिक उपकरणों जिनमें
              स्मार्ट डिजिटल बोर्ड , स्मार्ट एल इ डी टीवी , कंप्यूटर सिस्टम और
              सॉफ़्टवेयर से सुसज्जित हैं, जो छात्रों को व्यावहारिक अनुभव और
              नवीनतम तकनीकों को सीखने में मदद करती हैं।
              <br /> <br />
              हमारे उच्च योग्य शिक्षक छात्रों को प्रेरित करने के लिए नवीन तरीकों
              का उपयोग करते हैं और एक सहयोगी और इंटरैक्टिव सीखने का माहौल बनाते
              हैं।
            </Toast.Body>
          </Toast>
        </Col>

     {/* Libary */}
     <Col md={3} className="mb-3">
          <Card className="serv-card" onClick={toggleToastLibrary}>
            <Card.Img variant="top" className="card-img" src={card3} />
            <Card.Body>
              <Card.Title>पुस्तकालय</Card.Title>
              <Card.Text>
              2000 से अधिक निःशुल्क पाठ्यक्रम एवं साहित्यिक पुस्तकें
              </Card.Text>
            </Card.Body>
          </Card>
          {showToastLibrary && <div className="overlay" onClick={toggleToastLibrary}></div>}
          <Toast className="toast" show={showToastLibrary} onClose={toggleToastLibrary}>
            <Toast.Header>
              <strong className="mr-auto">पुस्तकालय</strong>
            </Toast.Header>
            <Toast.Body>
            हमारे स्कूल के पुस्तकालय में आपका स्वागत करती है! हमारी पुस्तकालय
              में 2000 से अधिक पुस्तकें हैं जो विभिन्न विषयों पर हैं, जैसे कि
              कहानियाँ, उपन्यास, कविताएँ, विज्ञान, इतिहास, भूगोल, गणित, और बहुत
              कुछ।
              <h6 className="text-white pt-3"> पुस्तक संग्रह</h6>
              हमारी पुस्तकालय में विशाल संग्रह है जो विभिन्न विषयों पर विविधता
              प्रदान करता है। यहाँ आपको नवीनतम बेस्टसेलर, क्लासिकल लेखकों की
              रचनाएँ, और शैक्षिक पुस्तकें मिलेंगी।
              <br />
              <br />
              <h6 className="text-white pt-3">रीडिंग रूम:</h6>
              हमारी पुस्तकालय में एक शांतिपूर्ण रीडिंग रूम भी है जहाँ छात्र आराम
              से बैठकर पुस्तकों को पढ़ सकते हैं। यहाँ उन्हें पढ़ने के लिए
              शांतिपूर्ण और आरामदायक वातावरण मिलेगा।
              <h6 className="text-white pt-3">समृद्ध साहित्यिक परिसर</h6>
              हमारी पुस्तकालय छात्रों को साहित्यिक अनुभव में समृद्ध करने का वादा
              करती है। यहाँ पर विभिन्न सम्मेलन, पुस्तक पाठ, और लेखकों के भेंट का
              आयोजन होता है।
              <br />
              <br />
              - हमारी पुस्तकालय सामुदायिक सेवाओं के माध्यम से समुदाय को संदेश
              पहुंचाती है। हम पुस्तक प्रदान करने के साथ-साथ, सामुदायिक
              गतिविधियों और संवाद सत्रों का आयोजन भी करते हैं।
              <br />
              हमारी पुस्तकालय आपको ज्ञान का खजाना खोलती है। आइए, आपकी शैक्षिक और
              साहित्यिक यात्रा को और भी सार्थक बनाएँ!
            </Toast.Body>
          </Toast>
        </Col>
      </Row>

  {/* second row content */}
      <Row>
      { /* Scholership */ }
        <Col md={3} className="mb-3">
          <Card className="serv-card" onClick={toggleToastScholership}>
            <Card.Img variant="top" className="card-img" src={card5} />
            <Card.Body>
              <Card.Title>छात्रवृत्ति व अन्य सुविधाएँ</Card.Title>
              <Card.Text>
              गार्गी पुरस्कार, ट्रांसपोर्ट वाउचर, यूनिफॉर्म, साइकिल वितरण
              </Card.Text>
            </Card.Body>
          </Card>
          {showToastScholership && <div className="overlay" onClick={toggleToastScholership}></div>}
          <Toast className="toast" show={showToastScholership} onClose={toggleToastScholership}>
            <Toast.Header>
              <strong className="mr-auto">छात्रवृत्ति व अन्य सुविधाएँ</strong>
            </Toast.Header>
            <Toast.Body>
            <h6 className="text-white pt-3"> छात्रवृत्ति :</h6>
              यह छात्रवृत्ति छात्रों को उनके अध्ययन और विकास
              के लिए आर्थिक सहायता प्रदान करती है ताकि वे अपने सपनों को पूरा
              करने के लिए पूरी तरह से समर्थ हो सकें।
              <br />
              <h6 className="text-white pt-3">गार्गी पुरस्कार:</h6>
              हमारे विद्यालय द्वारा में हर साल "गार्गी पुरस्कार" हेतु आवेदन की
              सुविधा दिलवाई जाती । उनके शैक्षिक उत्कृष्टता को
              सम्मानित करते हैं। यह पुरस्कार उन विद्यार्थियों को प्रदान किया
              जाता है जो अध्ययन में उत्कृष्टता और सामाजिक क्षेत्र में सक्रिय
              हैं।
              <h6 className="text-white pt-3">परिवहन वाउचर:</h6>
              विद्यार्थियों के लिए परिवहन वाउचर की पेशकश करते हैं। यह वाउचर उन छात्रों को सहायता प्रदान करता है जो अपने
              स्कूल आने जाने के लिए ट्रांसपोर्ट की जरूरत है। हमारा उद्देश्य है
              कि हर छात्र को उच्च शैक्षिक मानकों के साथ-साथ सुरक्षित और सहज
              परिवहन की सुविधा प्रदान की जाए।
              <br />
              <h6 className="text-white pt-3">
                नि:शुल्क वर्दीयों की प्राप्ति:
              </h6>
              हम नि:शुल्क वर्दियों की प्राप्ति की पेशकश करते हैं। यह सुनिश्चित करने के लिए किया गया है कि हर छात्र को
              स्कूल के लिए समान और उचित रूप में सजाया जाए। इससे हम शैक्षिक अनुभव
              को समानता और गरिमा के साथ संजोए रखने के लिए प्रतिबद्ध हैं।
              <br />
              <h6 className="text-white pt-3">साइकिल वितरण:</h6>
              यह हमारा प्रयास है कि हम स्कूली जीवन को सहज और सुरक्षित
              बनाएं, और छात्रों को स्वतंत्र तौर पर अपने स्कूल आने-जाने के लिए
              साइकिल की सुविधा प्रदान करें। इससे उनके स्वास्थ्य को भी ध्यान में
              रखा जाता है और पर्यावरण के प्रति जागरूकता को बढ़ावा दिया जाता है।
              <br />
            </Toast.Body>
          </Toast>
        </Col>


        {/* MidDayMeal */}
        <Col md={3} className="mb-3">
          <Card className="serv-card" onClick={toggleToastMidDayMeal}>
            <Card.Img variant="top" className="card-img" src={card6} />
            <Card.Body>
              <Card.Title>मिड-डे मील</Card.Title>
              <Card.Text>
              दोपहर का भोजन + दूध युक्त शुद्ध एवं पौष्टिक आहार
              </Card.Text>
            </Card.Body>
          </Card>
          {showToastMidDayMeal && <div className="overlay" onClick={toggleToastMidDayMeal}></div>}
          <Toast className="toast" show={showToastMidDayMeal} onClose={toggleToastMidDayMeal}>
            <Toast.Header>
              <strong className="mr-auto">  मिड-डे मील ( मध्याह्न भोजन योजना )</strong>
            </Toast.Header>
            <Toast.Body>
            हमारे विद्यालय में मध्याह्न भोजन योजना के तहत कक्षा 1 से 8 वींतक
              के छात्रों को पौष्टिक और संतुलित भोजन प्रदान किया जाता है। इस
              योजना का उद्देश्य छात्रों के स्वास्थ्य और पोषण को सुनिश्चित करना
              है ताकि वे बेहतर ढंग से पढ़ाई कर सकें और शारीरिक रूप से भी स्वस्थ
              रहें।
              <br />
              <h6 className="text-white pt-3">योजना की मुख्य विशेषताएं::</h6>
              <p>
                <b>पौष्टिक भोजन:</b> हर दिन ताजा और संतुलित भोजन तैयार किया जाता
                है, जिसमें अनाज, दालें, सब्जियां और फल शामिल होते हैं।
                <br />
                <b>स्वच्छता और सुरक्षा:</b> भोजन की तैयारी और परोसने में उच्चतम
                स्वच्छता मानकों का पालन किया जाता है।
                <br />
                <b>समय पर भोजन:</b> छात्रों को दोपहर में नियमित रूप से भोजन
                प्रदान किया जाता है,जिससे वे ऊर्जा प्राप्त कर सकें।
                <br />
                <b>नि:शुल्क सेवा:</b> यह सेवा सभी छात्रों के लिए नि:शुल्क है,
                जिससे आर्थिक रूप से कमजोर वर्ग के छात्रों को भी लाभ मिलता है।
              </p>
              <h6 className="text-white pt-3">योजना के लाभ:</h6>
              <b>शैक्षिक प्रदर्शन में सुधार:</b> अच्छा पोषण छात्रों की एकाग्रता
              और सीखने की क्षमता को बढ़ाता है। <br />
              <b>स्वच्छता और सुरक्षा:</b> भोजन की तैयारी और परोसने में उच्चतम
              स्वच्छता मानकों का पालन किया जाता है। <br />
              <b>स्वास्थ्य में सुधार:</b> संतुलित भोजन से छात्रों का शारीरिक
              विकास सही तरीके से होता है। <br />
              <b>समानता : </b> यह योजना सभी छात्रों को समान रूप से लाभान्वित
              करती है, जिससे शिक्षा में समानता को बढ़ावा मिलता है।
              <br />- हमारी मध्याह्न भोजन योजना का उद्देश्य छात्रों को स्वस्थ और
              प्रेरणादायक शैक्षिक वातावरण प्रदान करना है। हम अपने छात्रों के
              समग्र विकास के लिए प्रतिबद्ध हैं।
            </Toast.Body>
          </Toast>
        </Col>


 {/* Career */}
 <Col md={3} className="mb-3">
          <Card className="serv-card" onClick={toggleToastCareer}>
            <Card.Img variant="top" className="card-img" src={card7} />
            <Card.Body>
            <Card.Title>कैरियर मार्गदर्शन</Card.Title>
            <Card.Text>
                नैतिक शिक्षा के साथ-साथ कैरियर, परीक्षा से संबंधित मार्गदर्शन
              </Card.Text>
            </Card.Body>
          </Card>
          {showToastCareer && <div className="overlay" onClick={toggleToastCareer}></div>}
          <Toast className="toast" show={showToastCareer} onClose={toggleToastCareer}>
            <Toast.Header>
            <strong className="mr-auto">कैरियर मार्गदर्शन</strong>
            </Toast.Header>
            <Toast.Body>
            हमारे विद्यालय में करियर मार्गदर्शन सेवाएं उपलब्ध हैं, जो छात्रों
              को उनके भविष्य की योजना बनाने में सहायता करती हैं। हमारा उद्देश्य
              छात्रों को उनकी रुचियों और क्षमताओं के अनुसार सही करियर विकल्प
              चुनने में मदद करना है।
              <br />
              <br />
              <h6 className="text-white pt-3">सेवाओं की मुख्य विशेषताएं:</h6>
              <b>व्यक्तिगत परामर्श:</b>अनुभवी करियर काउंसलर छात्रों के साथ एक-एक
              करके बैठकर उनकी रुचियों, क्षमताओं और लक्ष्यों को समझते हैं।
              <br />
              <b>करियर कार्यशालाएं:</b> विभिन्न करियर विकल्पों, उच्च शिक्षा के
              रास्तों, और प्रवेश परीक्षाओं की जानकारी देने के लिए नियमित
              कार्यशालाओं का आयोजन किया जाता है।
              <br />
              <b>शैक्षिक परामर्श:</b> छात्रों को विभिन्न कोर्स, कॉलेज, और
              विश्वविद्यालयों के बारे में जानकारी दी जाती है, और उन्हें आवेदन
              प्रक्रिया में सहायता की जाती है।
              <br />
              <br />
              हमारा करियर मार्गदर्शन कार्यक्रम छात्रों को उनके सपनों को साकार
              करने और एक सफल भविष्य की ओर अग्रसर होने में मदद करने के लिए
              प्रतिबद्ध है।
            </Toast.Body>
          </Toast>
        </Col>

     {/* Libary */}
     <Col md={3} className="mb-3">
          <Card className="serv-card" onClick={toggleToastSupports}>
            <Card.Img variant="top" className="card-img" src={card4} />
            <Card.Body>
            <Card.Title>खेल / खेल मैदान</Card.Title>
              <Card.Text>
              फुटबॉल, वॉलीबॉल, कबड्डी, खो-खो के लिए अनुकूल खेल मैदान
              </Card.Text>
            </Card.Body>
          </Card>
          {showToastSupports && <div className="overlay" onClick={toggleToastSupports}></div>}
          <Toast className="toast" show={showToastSupports} onClose={toggleToastSupports}>
            <Toast.Header>
            <strong className="mr-auto">खेल मैदान</strong>
            </Toast.Header>
            <Toast.Body>
            हमारे विद्यालय में खेल और शारीरिक गतिविधियों को बढ़ावा देने के लिए
              उत्कृष्ट सुविधाएं उपलब्ध हैं। हमारा उद्देश्य छात्रों के समग्र
              विकास को सुनिश्चित करना है, जिसमें शारीरिक स्वास्थ्य, टीम वर्क, और
              खेल भावना का विकास शामिल है।
              <br />
              <br />
              <h6 className="text-white pt-3">सेवाओं की मुख्य विशेषताएं:</h6>
              <b>विशाल खेल का मैदान:</b> हमारा स्कूल एक विशाल खेल मैदान से
              सुसज्जित है, जहां छात्र विभिन्न खेलों का आनंद ले सकते हैं जैसे
              क्रिकेट, फुटबॉल, हॉकी, और एथलेटिक्स।
              <br />
              <b>खेल उपकरण:</b> हमारे पास उच्च गुणवत्ता वाले खेल उपकरण उपलब्ध
              हैं, जो छात्रों को विभिन्न खेलों में भाग लेने के लिए प्रेरित करते
              हैं।
              <br />
              <b>अंतर-विद्यालयी प्रतियोगिताएं:</b> हमारे छात्र विभिन्न
              अंतर-विद्यालयी और राज्य-स्तरीय खेल प्रतियोगिताओं में भाग लेते हैं,
              जिससे उन्हें अपनी प्रतिभा दिखाने और अनुभव प्राप्त करने का अवसर
              मिलता है।  
              <br />
              <br />
              <h6 className="text-white pt-3">सेवाओं के लाभ:</h6>
              <b>शारीरिक स्वास्थ्य में सुधार:</b> नियमित शारीरिक गतिविधियां
              छात्रों के शारीरिक स्वास्थ्य को बढ़ावा देती हैं।
              <br />
              <b>टीम वर्क और नेतृत्व:</b> खेल छात्रों को टीम वर्क, नेतृत्व, और
              खेल भावना सिखाते हैं।
              <br />
              <b>मनोबल और आत्मविश्वास: </b>खेलकूद में भाग लेने से छात्रों का
              आत्मविश्वास और मनोबल बढ़ता है।
              <br />
              <br />
              हमारा खेल और खेल का मैदान कार्यक्रम छात्रों को उनके शारीरिक और
              मानसिक विकास में सहायता करता है, जिससे वे एक स्वस्थ और संतुलित
              जीवन जी सकें।
            </Toast.Body>
          </Toast>
        </Col>
      </Row>

    </Container>




  );
};

export default ServiceCard;
