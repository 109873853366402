import React, { useState, useEffect } from "react";
import "./Gallery.css";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { db, storage, auth } from '../Firebase';
import { collection, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import AboutButtons from "../componets/AboutButtons";

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [preview, setPreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [caption, setCaption] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "gallery"));
        const imageList = [];
        querySnapshot.forEach((doc) => {
          imageList.push({ id: doc.id, ...doc.data() });
        });
        setImages(imageList);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchImages();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userCredential'));
    setLoggedIn(!!user);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setLoggedIn(false);
        localStorage.removeItem('userCredential');
      } else {
        setLoggedIn(true);
        localStorage.setItem('userCredential', JSON.stringify(user));
      }
    });

    return () => unsubscribe();
  }, []);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDelete = async () => {
    const imageToDelete = images[currentImageIndex];
    const imageRef = ref(storage, imageToDelete.storagePath);

    try {
      await deleteObject(imageRef);
      await deleteDoc(doc(db, "gallery", imageToDelete.id));

      setImages((prevImages) => {
        const updatedImages = prevImages.filter((_, index) => index !== currentImageIndex);

        if (currentImageIndex >= updatedImages.length) {
          setCurrentImageIndex(updatedImages.length - 1);
        }

        return updatedImages;
      });

      if (images.length === 1) {
        handleClose();
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setUploading(true);

    const file = selectedFile;
    const storageRef = ref(storage, `gallery/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.error("Error uploading file:", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        const docRef = await addDoc(collection(db, "gallery"), {
          url: downloadURL,
          storagePath: `gallery/${file.name}`,
          caption: caption,
        });
        setImages([...images, { id: docRef.id, url: downloadURL, storagePath: `gallery/${file.name}`, caption }]);
        setPreview("");
        setSelectedFile(null);
        setCaption("");
        setUploading(false);
      }
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  return (
    <div className="page-content">
      <Container className="page-header" fluid>
        <h2 className="text-white text-center">Gallery</h2>
      </Container>

      {loggedIn && (
        <Container className="mb-4 text-center">
          <input
            type="file"
            id="upload-input"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Button variant="primary" onClick={() => document.getElementById('upload-input').click()}>
            Select Image
          </Button>
          {preview && (
            <div className="preview-container mt-3">
              <img
                src={preview}
                alt="Preview"
                style={{ maxWidth: '50%', height: 'auto', marginBottom: '10px' }}
              />
              <Form.Control
                type="text"
                placeholder="Enter a caption"
                value={caption}
                onChange={handleCaptionChange}
                className="mt-2"
              />
              <Button variant="success" onClick={handleUpload} className="mt-2" disabled={uploading}>
                {uploading ? <Spinner animation="border" size="sm" /> : "Upload"}
              </Button>
            </div>
          )}
        </Container>
      )}

      <Container className="gallery">
        <Row>
          {images.map((image, index) => (
            <Col xs={12} md={4} key={image.id}>
              {image.url ? (
                <Image
                  className="pk-img"
                  src={image.url}
                  onClick={() => handleImageClick(index)}
                  loading="lazy"
                  fluid
                />
              ) : (
                <div className="placeholder">
                  <p>No Image Available</p>
                </div>
              )}
            </Col>
          ))}
        </Row>
      </Container>

      <Modal size="lg" show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{images[currentImageIndex]?.caption || "No Caption"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={images[currentImageIndex]?.url} fluid />
        </Modal.Body>
        <Modal.Footer>
          {loggedIn && (
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button variant="secondary" onClick={handlePrev}>
            ←
          </Button>
          <Button variant="secondary" onClick={handleNext}>
            →
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <AboutButtons />
    </div>
  );
};

export default Gallery;
