import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-dark text-light pt-5">
      <Container>
        <Row className="mx-2">
          <Col md={3} className="mb-4">
            <h5 className="text-white" >Govt. Sen. Sec. School Bhopalpura</h5>
            <p>
             Address :  Bhopalpura 1LM , Block : Suratgarh , District : SriGanganagar , Rajasthan 335704
            </p>
          </Col>
          <Col md={3} className="mb-4">
            <h5 className="text-white">Pages</h5>
            <ul className="list-unstyled">
            <li>
              <Link  className="nav-link " to='/'>Home</Link>
              </li>
              <li>
              <Link  className="nav-link " to='/about'>About</Link>
              </li>
              <li>
                <Link  className="nav-link " to='/staff'>About Staff</Link>
              </li>
              <li>
              <Link  className="nav-link " to='/student'>Student Corner</Link>
              </li>
              <li>
                <Link  className="nav-link " to='/gallery'>Gallery</Link>
              </li>
              
            </ul>
          </Col>
         
          <Col md={3}className="mb-4">
            <h5 className="text-white">Quick Links</h5>
            <ul className="list-unstyled">
            <li>
                <a className="nav-link " target="blank" href="https://education.rajasthan.gov.in/home">Rajasthan Education</a>   
              </li>
              <li>
                <a className="nav-link " target="blank" href="https://rajshaladarpan.nic.in/">Shala Darpan</a>
              </li>
              <li>
                {/* <a className="nav-link " href='/contact'>Contact</a> */}
                <Link  className="nav-link " to='/contact'>Contact</Link>
              </li>
              <li>
                <Link  className="nav-link " to='/updates'>Updates</Link>
              </li>
              <li>
                <Link  className="nav-link " to='/result'>Result</Link>
              </li>
           
              {/* Add more links */}
            </ul>
          </Col>

          <Col md={3}className="mb-4">
            <h5 className="text-white">Contact Info</h5>
            <ul className="list-unstyled">
              <li>
                Email Us : <a className="nav-link " href="mailto:ictgsssbhopalpura@gmail.com">ictgsssbhopalpura@gmail.com</a>
              </li>
              <li>
              Call Us : <a className="nav-link " href="tel:+91 99297 43358"> ✆ Click To Call</a>
              </li>
            </ul>
          </Col>


        </Row>
        <Row>
          <Col className="text-center mt-3">
            <p>
             Copyright &copy; {new Date().getFullYear()} GSSS Bhopalpura. All Rights
              Reserved.
            </p>
            <p>Design & Developed By <a className="nav-link " href=" https://www.facebook.com/profile.php?id=61558447314081&mibextid=ZbWKwL">Pankaj K. Swami : ICT-GSSS Bhopalpura</a></p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
