import React from "react";
import Marquee from "react-fast-marquee";
// import NewsUpdate from "../componets/NewsUpdate";
import AboutButtons from "../componets/AboutButtons";
import ServiceCard from "../componets/ServiceCard";

import Image from "react-bootstrap/Image";


import {
  Container,
  Row,
  Col
} from "react-bootstrap";




//hero images

//card iamges & Toast

const Result = () => {
  return (
    <div className="page-content">
      <Container className="page-header" fluid>
        <h2 className="text-white text-center">Result Section</h2>
      </Container>

      <div>
        <Marquee className="News_tiker" pauseOnHover>
          &nbsp;&nbsp; &#8680; &nbsp;&nbsp; यहाँ विद्यार्थी के लिए आवश्यक सुचना
          दिखाई जाएगी जो की परीक्षा या किसी अन्य प्रयोजन के लिए दी गई हो
          &nbsp;&nbsp;
          <a className=" pk-backround-white " target="_blank" rel="noreferrer" href="https://vidyarthi24.netlify.app/"> Vidyarthi-24 &nbsp; ऑनलाइन ब्लॉग द्वारा पढ़ें</a>
        </Marquee>

        {/* Testimonials/Success Stories Section */}

        {/* Welcome Message Sention */}
        <Container fluid className="pt-5 pb-4 bg-light">
          <Row className="d-sm-flex m-sm-5 mb-4 align-content-center " lg={12}>

            <Col sm className="pk-border-box  p-3 m-2">

              <h5>आंकड़े : परीक्षा परिणाम वर्ष 2023-24 </h5>
              <p>
                <hr />
                <b>कक्षा 12 वीं कला वर्ग </b>
                <hr />
                <ul>
                  <li>90 % से अधिक वाले विद्यार्थी - 2</li>
                  <li>80 % से अधिक वाले विद्यार्थी - 15</li>
                  <li>प्रथम श्रेणी - 32</li>
                  <li>द्वितीय श्रेणी - 5</li>
                  <li>गार्गी पुरस्कार - 17</li>
                </ul>
                <hr />
                <b>कक्षा 10 वीं</b>
                <hr />
                <ul>
                  <li>80 % से अधिक वाले विद्यार्थी - 5</li>
                  <li>70 % से अधिक वाले विद्यार्थी - 4</li>
                  <li>प्रथम श्रेणी - 17</li>
                  <li>द्वितीय श्रेणी - 21</li>
                  <li>गार्गी पुरस्कार - 3</li>
                </ul>
               
              </p>
            </Col>
            <Col sm className="pk-border-box  p-3 m-2">
              <h5>कक्षा 10 वीं एवं 12 वीं परीक्षा परिणाम 2023-24 </h5>
              <Image className="profileImg img-thumbnail"
                src="Image/result2324.png"
                rounded
              />
            </Col>
            <Col sm className="pk-border-box  p-3 m-2">

              <h5>गत वर्ष एवं अन्य परीक्षा परिणाम</h5>
              <p>
                <hr/>
              कोई जानकारी उपलब्ध नहीं !
              </p>
            </Col>

          </Row>
        </Container>

        {/* Highlights/Services Section */}
        <ServiceCard />
        <AboutButtons />
      </div>
    </div>
  );
};

export default Result;
